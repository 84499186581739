import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@fontsource/montserrat"; // Defaults to weight 400.
import Side_drawer from "./organism/side_drawer";
import Dashboard_admin from "./pages/dashboard_admin";
import Course_page from "./pages/course_page";
import Authentication_page from "./pages/authentication_page";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Side_drawer label={"dashboard admin"}>
        <Dashboard_admin></Dashboard_admin>
      </Side_drawer>
    ),
  },
  {
    path: "/dashboard-admin",
    element: (
      <Side_drawer label={"dashboard admin"}>
        <Dashboard_admin></Dashboard_admin>
      </Side_drawer>
    ),
  },
  {
    path: "/dashboard-teacher",
    element: (
      <Side_drawer label={"dashboard teacher"}>
        <Dashboard_admin></Dashboard_admin>
      </Side_drawer>
    ),
  },
  {
    path: "/course",
    element: (
      <Side_drawer label={"course"}>
        <Course_page />
      </Side_drawer>
    ),
  },
  {
    path: "/authentication",
    element: (
      <Side_drawer label={"authentication"}>
        <Authentication_page/>
      </Side_drawer>
    ),
  },
  {
    path: "/settings",
    element: (
      <Side_drawer label={"settings"}>
        <h1>Hello world!</h1>
      </Side_drawer>
    ),
  },
  {
    path: "/error",
    element: (
      <Side_drawer label={"error"}>
        <h1>Hello world!</h1>
      </Side_drawer>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
